.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* target the active bullet */
span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #FFA049;
    opacity: 1;
}

.test-answer:hover {
    background-color: #e4e6e8 !important;
}

.test-answer:active{
    background-color: #FFA149 !important;
}

@media (hover: hover) {
    .test-answer:hover {
        background-color: #FFA149 !important;
    }
}

